import React from 'react';
import PropTypes from 'prop-types';

export default class ButtonGroup extends React.Component {
    static propTypes = {
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                buttonType: PropTypes.number.isRequired,
                component: PropTypes.node.isRequired,
            }),
        ).isRequired,
    };

    get primaryButtonTypes() {
        return [
            BUTTON_TYPE.INTEGRATED_BOOK_NOW,
            BUTTON_TYPE.CALL_NOW,
            BUTTON_TYPE.BOOK_NOW,
            BUTTON_TYPE.REFERRAL,
            BUTTON_TYPE.APPOINTMENT,
        ];
    }

    getOrderedButtons(buttons) {
        if (buttons?.length) {
            const orderedButtons = buttons.sort(
                (a, b) => a.buttonType - b.buttonType,
            );
            return orderedButtons.slice(0, 3);
        }

        return [];
    }

    getIcon(buttonType) {
        switch (buttonType) {
            case BUTTON_TYPE.CALL_NOW:
                return 'call';

            case BUTTON_TYPE.BOOK_NOW:
            case BUTTON_TYPE.INTEGRATED_BOOK_NOW:
            case BUTTON_TYPE.APPOINTMENT:
                return 'book';

            case BUTTON_TYPE.REFERRAL:
                return 'write';

            default:
                return '';
        }
    }

    render() {
        const buttons = this.getOrderedButtons(this.props.buttons);

        return (
            <>
                {buttons.map((button, index) => {
                    const {buttonType} = button;
                    let component;
                    if (
                        index === 0 &&
                        this.primaryButtonTypes.includes(buttonType)
                    ) {
                        const currentClass =
                            button.component.props.customClass || '';
                        component = React.cloneElement(button.component, {
                            icon: this.getIcon(buttonType),
                            customClass: `${currentClass} positive`.trim(),
                        });
                    } else {
                        component = React.cloneElement(button.component);
                    }
                    return (
                        <React.Fragment key={buttonType}>
                            {component}
                        </React.Fragment>
                    );
                })}
            </>
        );
    }
}

export const BUTTON_TYPE = {
    REFERRAL: 1,
    SECURE_MESSAGE: 2,
    BOOK_NOW: 3, // Request an appointment -- to be deprecated
    INTEGRATED_BOOK_NOW: 4, // (Cliniko) Booking Data for new PMS
    CALL_NOW: 5,
    APPOINTMENT: 6, // Request appointment email
    PROMOTION: 7,
    FAVOURITE: 8,
    PRINT: 9,
};
