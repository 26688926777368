import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'core/components/Tooltip.js';
import Icon from 'core/components/Icon.js';

function HicapsBadge({isPracticeLocation = false}) {
    const tooltipContent = (
        <p>
            {
                'Claim on-the-spot with HICAPS: use your health fund/Medicare card when paying for the service to receive your rebate instantly. You will only need to pay the difference between the service fee and the rebate.'
            }
        </p>
    );
    const practices = isPracticeLocation
        ? 'this practice'
        : 'selected practices';
    return (
        <figure className="hicaps">
            <Tooltip content={tooltipContent} defaultPosition="top">
                <Icon name="info" />
            </Tooltip>
            <Icon name="hicaps" />
            <figcaption>
                {`Instant rebates available at ${practices} with your health fund card`}
            </figcaption>
        </figure>
    );
}

HicapsBadge.propTypes = {
    isPracticeLocation: PropTypes.bool,
};

export default HicapsBadge;
