import PropTypes from 'prop-types';
import React from 'react';

import events from 'events.js';
import http from 'http.js';
import autobind from 'common/decorators/autobind.js';
import Icon from 'core/components/Icon';
import ReCaptcha from 'core/components/ReCaptcha';
import {PAGE_DATA} from 'core/constants.js';

export default class ReportModal extends React.Component {
    static propTypes = {
        availableCategories: PropTypes.arrayOf(PropTypes.string.isRequired)
            .isRequired,
        closeModal: PropTypes.func.isRequired,
        displayName: PropTypes.string.isRequired,
        isClient: PropTypes.bool.isRequired,
        pageType: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            captchaToken: undefined,
            emailInput: '',
            issueDescription: undefined,
            nameInput: '',
            selectedCategory: '',
            showDescriptionError: false,
            showCategoryError: false,
            submitDone: false,
            submitError: false,
            submitting: false,
        };

        this.categoryRef = React.createRef();
        this.descriptionRef = React.createRef();
        this.recaptcha = React.createRef();
    }

    componentDidMount() {
        events.listen(document.body, 'click', this.handleClick);
        this.setFocusOnCategory(this.categoryRef.current);
    }

    componentWillUnmount() {
        events.unlisten(document.body, 'click', this.handleClick);
    }

    @autobind
    setFocusOnCategory(element) {
        setTimeout(() => {
            element.focus();
        }, 0);
    }

    @autobind
    handleClick() {
        this.setState({
            showDescriptionError: false,
            showCategoryError: false,
        });
    }

    @autobind
    handleCategoryChange(event) {
        this.setState({selectedCategory: event.currentTarget.value});
    }

    @autobind
    handleDescriptionChange(event) {
        this.setState({issueDescription: event.currentTarget.value});
    }

    @autobind
    setCaptchaToken(captchaToken) {
        this.setState({captchaToken});
    }

    get formData() {
        return PAGE_DATA[this.props.pageType];
    }

    @autobind
    async handleFormSubmit(event) {
        event.preventDefault();
        // We using custom validation instead of 'required' because
        // we are preventing a normal from of action of the event
        if (this.displayErrorMessages()) {
            return null;
        }
        const {isClient} = this.props;
        let {captchaToken} = this.state;
        const {issueDescription, selectedCategory} = this.state;
        const {emailInput, nameInput} = this.state;
        if (this.recaptcha?.current) {
            if (this.recaptcha.current.shouldRegenerateRecaptchaToken()) {
                captchaToken = await this.recaptcha.current.renewRecaptchaToken();
            }
        }
        const adminUrl = document.body.dataset.adminUrl;
        const url = '/api/base/v1/email-support/';
        const data = {
            'admin_url': adminUrl ? adminUrl : undefined,
            'captcha_token': captchaToken,
            'device_info': `UserAgent: ${navigator.userAgent}`,
            'display_name': this.props.displayName,
            'issue_category': selectedCategory,
            'issue_description': issueDescription,
            'issued_page_url': window.location.href,
            ...this.formData,
        };
        if (isClient) {
            data.client = true;
        }
        if (emailInput) {
            data['reporting_email'] = emailInput;
        }
        if (nameInput) {
            data['reporting_name'] = nameInput;
        }

        const newState = {};
        try {
            this.setState({submitting: true});
            await http.post({url, data});
        } catch (error) {
            newState.submitError = true;
            throw new Error(error);
        } finally {
            newState.submitDone = true;
            newState.submitting = false;
            this.setState(newState);
        }
    }

    @autobind
    handleSubmitMoreFeedback() {
        this.setState({
            issueDescription: null,
            selectedCategory: '',
            submitDone: false,
        });
    }

    displayErrorMessages() {
        const showDescriptionError = this.state.issueDescription
            ? false
            : true;
        const showCategoryError = this.state.selectedCategory ? false : true;
        this.setState({
            showCategoryError,
            showDescriptionError,
        });
        return showCategoryError || showDescriptionError;
    }

    @autobind
    handleNameInput(e) {
        this.setState({
            nameInput: e.target.value,
        });
    }

    @autobind
    handleEmailInput(e) {
        this.setState({
            emailInput: e.target.value,
        });
    }

    renderRequiredToolTip(message) {
        return (
            <span className="notice" role="alert">
                {message}
            </span>
        );
    }

    renderSubmitMessage() {
        const {submitError} = this.state;
        if (submitError) {
            return (
                <p className="notification error">
                    {
                        'Sorry an error has occurred, please refresh and try again.'
                    }
                </p>
            );
        } else {
            return (
                <>
                    <p className="notification success">
                        {
                            'Issue reported. Thank you for providing this information. We will look into this and aim to resolve any issue as soon as possible.'
                        }
                    </p>
                    <button
                        onClick={this.handleSubmitMoreFeedback}
                        className="cta"
                    >
                        {'Submit more feedback'}
                    </button>
                </>
            );
        }
    }

    renderSubmitForm() {
        const {
            showDescriptionError,
            showCategoryError,
            submitting,
        } = this.state;
        const {availableCategories} = this.props;
        return (
            <form className="report-modal">
                <label>
                    {showCategoryError &&
                        this.renderRequiredToolTip('Please pick a category')}
                    {'Incorrect section:'}
                    <select
                        onChange={this.handleCategoryChange}
                        ref={this.categoryRef}
                        value={this.state.selectedCategory}
                    >
                        <option disabled value="">
                            {'Select section'}
                        </option>
                        {availableCategories.map((categoryName) => {
                            return (
                                <option key={categoryName}>
                                    {categoryName}
                                </option>
                            );
                        })}
                    </select>
                </label>
                <label>
                    {showDescriptionError &&
                        this.renderRequiredToolTip(
                            'Please provide description of the issue',
                        )}
                    {'Description of issue:'}
                    <textarea
                        data-private
                        id="description"
                        onChange={this.handleDescriptionChange}
                        placeholder="Please describe the issue in as much detail as possible"
                        ref={this.descriptionRef}
                    />
                </label>
                <p>
                    {
                        'Please consider leaving your contact details in case we need to follow up with you about your reported issue.'
                    }
                </p>
                <label>
                    {'Your name '}
                    <em>{'(optional)'}</em>
                    <input
                        data-private
                        onChange={this.handleNameInput}
                        type="text"
                        value={this.state.nameInput}
                    />
                </label>
                <label>
                    {'Your email address '}
                    <em>{'(optional)'}</em>
                    <input
                        data-private
                        onChange={this.handleEmailInput}
                        type="email"
                        value={this.state.emailInput}
                    />
                </label>
                <ReCaptcha
                    action="report_issue"
                    setCaptchaToken={this.setCaptchaToken}
                    ref={this.recaptcha}
                />
                <button
                    className="cta"
                    disabled={submitting}
                    onClick={this.handleFormSubmit}
                    type="submit"
                >
                    {'Submit feedback'}
                </button>
            </form>
        );
    }

    render() {
        const {submitDone} = this.state;
        const {closeModal} = this.props;
        return (
            <>
                <button className="dismiss" onClick={closeModal} type="button">
                    <Icon name="close" />
                </button>
                <h1>{'Report an issue on this page'}</h1>
                {submitDone
                    ? this.renderSubmitMessage()
                    : this.renderSubmitForm()}
            </>
        );
    }
}
