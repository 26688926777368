import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';

export default class CollapsibleText extends React.Component {
    static propTypes = {
        collapseText: PropTypes.string,
        collapsed: PropTypes.bool,
        expandText: PropTypes.string,
        maxWords: PropTypes.number.isRequired,
        renderHtml: PropTypes.bool,
        showToggle: PropTypes.bool,
        text: PropTypes.string,
    };

    static defaultProps = {
        collapsed: true,
        showToggle: true,
        expandText: 'Show more',
        renderHtml: false,
    };

    constructor(props) {
        super(props);

        this.collapsedText = this.truncateHTML(props.text);
        this.state = {
            collapsed: this.textExceedsMaxWords ? props.collapsed : false,
            collapsedText: '',
        };
    }

    splitText(text) {
        // Split the text on white space but keep any beginning and end
        // whitespaces as they are.
        // For instance '  This is a nice text ' becomes
        // ['  This', 'is', 'a', 'nice', 'text ']
        if (!text) {
            return [];
        }
        let firstSpace = text.match(/^\s+/g);
        firstSpace = firstSpace && firstSpace.length > 0 ? firstSpace[0] : '';
        let lastSpace = text.match(/\s+$/g);
        lastSpace = lastSpace && lastSpace.length > 0 ? lastSpace[0] : '';
        const split = text.split(/\s+/).filter((word) => !!word);
        if (!split.length) {
            return [];
        }
        split[0] = firstSpace + split[0];
        split[split.length - 1] += lastSpace;
        return split;
    }

    truncate(wrapper, element, truncatedAmount) {
        const {maxWords} = this.props;
        let truncated = false;
        const children = [...element.childNodes];
        for (const child of children) {
            if (
                child.childNodes.length > 0 ||
                typeof child.textContent === 'undefined'
            ) {
                const {amount, truncatedHere} = this.truncate(
                    wrapper,
                    child,
                    truncatedAmount,
                );
                truncatedAmount = amount;
                truncated = truncated || truncatedHere;
                if (!truncatedHere && truncatedAmount >= maxWords) {
                    child.parentNode.removeChild(child);
                }
            } else {
                if (truncatedAmount >= maxWords) {
                    child.parentNode.removeChild(child);
                } else {
                    const text = child.textContent;
                    let words = this.splitText(text);
                    words = words.slice(0, maxWords - truncatedAmount);
                    truncatedAmount += words.length;
                    child.textContent = words.join(' ');
                    if (truncatedAmount >= maxWords) {
                        child.textContent += '\u2026 ';
                        truncated = true;
                    }
                }
            }
        }
        return {
            amount: truncatedAmount,
            truncatedHere: truncated,
        };
    }

    truncateHTML(html) {
        const wrapper = document.createElement('div');
        wrapper.innerHTML = html;
        const {amount} = this.truncate(wrapper, wrapper, 0);
        this.textExceedsMaxWords = amount >= this.props.maxWords;
        return wrapper.innerHTML;
    }

    htmlToText(text) {
        const div = document.createElement('div');
        div.innerHTML = text;
        return div.textContent;
    }

    @autobind
    toggleCollapsed() {
        this.setState((state) => ({
            collapsed: !state.collapsed,
        }));
    }

    renderToggle() {
        const {collapseText, expandText, showToggle} = this.props;
        if (!this.textExceedsMaxWords || !showToggle) {
            return null;
        }

        const toggleText = this.state.collapsed ? expandText : collapseText;

        return (
            <button
                className="button-handle collapsible-text"
                onClick={this.toggleCollapsed}
            >
                <span>{toggleText}</span>
            </button>
        );
    }

    renderCollapsed() {
        return (
            <>
                <div
                    className="truncated-text"
                    dangerouslySetInnerHTML={{__html: this.collapsedText}}
                />
                {this.renderToggle()}
            </>
        );
    }

    renderHtml() {
        const html = {__html: this.props.text};
        return (
            <>
                <div aria-live="polite" dangerouslySetInnerHTML={html} />
                {this.props.collapseText && this.renderToggle()}
            </>
        );
    }

    renderText() {
        let {text} = this.props;
        if (this.collapsedText && this.state.collapsed) {
            text = this.collapsedText;
        }
        return (
            <>
                <p>{text}</p>
                {this.props.collapseText && this.renderToggle()}
            </>
        );
    }

    render() {
        if (this.state.collapsed) {
            return this.renderCollapsed();
        } else if (this.props.renderHtml) {
            return this.renderHtml();
        } else {
            return this.renderText();
        }
    }
}
